import { extend, configure, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, excluded, min, max, min_value, max_value, image, size, dimensions, oneOf, integer, ext } from 'vee-validate/dist/rules';
import i18n from '../i18n';
import { PhoneRule, PostalCodeRule, EmailRule, FalsyRule, MinDimensionsRule, ImageRatioRule, RequiredLanguage } from './customRules';
import Vue from 'vue';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', required);
extend('excluded', excluded);
extend('max', max);
extend('min', min);
extend('min_value', min_value);
extend('max_value', max_value);
extend('image', image);
extend('size', size);
extend('dimensions', dimensions);
extend('oneOf', oneOf);
extend('integer', integer);
extend('ext', ext);
extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return regex.test(value);
  },
  message: i18n.t('validations.decimal')
});
PhoneRule.extend();
PostalCodeRule.extend();
EmailRule.extend();
FalsyRule.extend();
MinDimensionsRule.extend();
ImageRatioRule.extend();
RequiredLanguage.extend();

configure({
  defaultMessage: (_, values) => i18n.t(`validations.${values._rule_}`, values),
  classes: {
    invalid: 'hasError md-invalid'
  }
});

export default class baseValidation {
}
