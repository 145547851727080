import { extend } from 'vee-validate';

export class PhoneRule {
  static extend() {
    extend('phone', {
      validate: value => {
        let phoneNumber = value.replace(/[^0-9]/g, '');
        return phoneNumber.length === 10 || value.length === 0;
      }
    });
  }
}

export class RequiredLanguage {
  static extend() {
    extend('requiredLanguage', {
      validate: value => {
        return value;
      }
    });
  }
}

export class PostalCodeRule {
  static extend() {
    extend('postalCode', {
      validate: value => {
        let ca = new RegExp(/^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][ -]?\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]\d$/);
        return ca.test(value.toString());
      }
    });
  }
}

export class EmailRule {
  static extend() {
    extend('extendedEmail', {
      validate: email => {
        let ca = new RegExp(/^([a-zA-Z0-9_%+-]+([.][a-zA-Z0-9_%+-]+)*)@(((([a-zA-Z0-9])+(-[a-zA-Z0-9])*)+\.)+([a-zA-Z]){2,})$/);
        return ca.test(email.toString());
      }
    });
  }
}

export class FalsyRule {
  static extend() {
    extend('falsy', {
      validate: value => {
        return !value;
      }
    });
  }
}

export class NasRule {
  static extend() {
    extend('nas', {
      validate: sin => {
        var check, even, tot;

        if (typeof sin === 'number') {
          sin = sin.toString();
        }

        sin = sin.replace(/[^0-9]/g, '');

        if (sin.length === 9 && sin.substring(0, 1) !== '0') {
          // convert to an array & pop off the check digit
          sin = sin.split('');
          check = +sin.pop();

          even = sin
            .filter(function (_, i) { return i % 2; })
            .map(function (n) { return n * 2; })
            .join('').split('');

          tot = sin
            .filter(function (_, i) { return !(i % 2); })
            .concat(even)
            .map(function (n) { return +n; })
            .reduce(function (acc, cur) { return acc + cur; });

          return check === (10 - tot % 10) % 10;
        }
        return false;
      }
    });
  }
}

export class MinDimensionsRule {
  static extend() {
    extend('minDimensionsRule', {
      validate(files, [width, height]) {
        const validateImage = (file, width, height) => {
          const URL = window.URL || window.webkitURL;
          return new Promise(resolve => {
            const image = new Image();
            image.onerror = () => { return resolve(false); };
            image.onload = () => {
              return resolve(
                image.width >= Number(width) && image.height >= Number(height)
              );
            };
            image.src = URL.createObjectURL(file);
          });
        }; 
        const list = [];
        for (let i = 0; i < files.length; i++) {
          // if file is not an image, reject.
          if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
            return Promise.resolve({ valid: false });
          }
          list.push(files[i]);
        }
        return Promise.all(list.map(file => validateImage(file, width, height))).then(function (values) {
          return values.every(function (v) { return v; });
        });
      }
    });
  }
}

export class ImageRatioRule {
  static extend() {
    extend('imageRatioRule', {
      validate(files, [width, height]) {
        const validateImage = (file, width, height) => {
          const URL = window.URL || window.webkitURL;
          return new Promise(resolve => {
            const image = new Image();
            image.onerror = () => { return resolve(false); };
            image.onload = () => {
              return resolve(
                image.width / image.height === Number(width) / Number(height)
              );
            };
            image.src = URL.createObjectURL(file);
          });
        };
        const list = [];
        for (let i = 0; i < files.length; i++) {
          // if file is not an image, reject.
          if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
            return Promise.resolve({ valid: false });
          }
          list.push(files[i]);
        }
        return Promise.all(list.map(file => validateImage(file, width, height))).then(function (values) {
          return values.every(function (v) { return v; });
        });
      }
    });
  }
}
