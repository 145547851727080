<template>
  <div>
    <span v-text="selectedText"></span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  mounted: function () {
    this.$store.dispatch('category/LoadOmniMajorCategories');
  },
  computed: {
    majorCategories() {
      return this.$store.state.category.omniMajorCategories;
    },
    selectedCategory() {
      return this.majorCategories.find(category => category.OmniMajorCategoryId === this.value);
    },
    selectedText() {
      if (this.selectedCategory) {
        return this.selectedCategory.Code;
      }

      return '';
    }
  }
};
</script>
